import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import {Link} from "gatsby"

const NavFeature = () => (
<div className="feature aspect-w-16 aspect-h-9 absolute left-0 top-0 w-full lg:relative">
    <Link to="/tc530">
        <div className="bg-gray-300 border-2 border-gray-475">
            <StaticImage className="h-full" src="../assets/images/nav/tc530-feature.jpg" alt="TC530 guitars" width={1920} quality={75} aspectRatio={1.78}  />
        </div>
    </Link>
</div>
)

export default NavFeature
