import BackToTop from "./BackToTop"
import FooterNav from "./FooterNav"
import {Link} from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Footer = ({siteTitle}) => (
  <footer id="footer" className="text-xs py-5 px-5 text-gray-475 pb-safe max-w-1920 mx-auto">
    <h2 className="sr-only">Site Footer</h2>

    <BackToTop />
    <FooterNav />
    
    <p className="inline mr-1 md:block">All trademarks and copyrighted materials are the property of their respective owners.</p>
    <p className="inline md:block"><span className="mr-2">This website contains media (images, video, etc.) not owned by Tal's Guide.</span> <span className="legal-link"><Link to="/fair-use">Fair Use Notice</Link></span></p>
    
    <div className="flex items-baseline border-t border-gray-700 pt-3 mt-3 flex-col-reverse md:flex-row"> 
      <p className="copyright w-full md:w-auto">&copy; 2019-{new Date().getFullYear()} {siteTitle}. All rights reserved.
      This is a fan-made website, it is not affiliated with
      or endorsed by Ibanez, Hoshino USA, Inc., Hoshino Gakki Co., Ltd.</p>
      
      <ul className="flex pb-3 md:pl-5 md:py-0 ">
        <li className="legal-link mr-2 pr-2 border-r border-gray-700"><Link to="/privacy">Privacy Policy</Link></li>
        <li className="legal-link mr-2 pr-2 border-r border-gray-700"><Link to="/terms">Terms of Use</Link></li>
        <li className="legal-link"><Link to="/sitemap">Site Map</Link></li>
      </ul>
    </div>
  </footer>
)

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
