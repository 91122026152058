import React from "react"
import PropTypes from "prop-types"
import {useStaticQuery, graphql} from "gatsby"
import Header from "./Header"
import Footer from "./Footer"


const Layout = ({children, headerOverlap}) => {
  const data = useStaticQuery(graphql`
	query SiteTitleQuery {
	  site {
		siteMetadata {
		  title
		}
	  }
	}
  `);

  return (
	<div id="top">
		<Header siteTitle={data.site.siteMetadata.title} headerOverlap={headerOverlap} />
		<main id="main">{children}</main>
		<Footer siteTitle={data.site.siteMetadata.title} />
	</div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
