import {Link} from "gatsby"
import NavFeature from "./NavFeature"
import React from "react"

const MainNav = ({menu_open, setMenuOpen}) => {

/* NOTE: The <div> element is capturing bubbled events (keyboard interaction) */
/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */
return <div className={`main-nav ${menu_open ? 'menu-open' : ''}`} onBlur={({target, relatedTarget, currentTarget}) => {
	if (!relatedTarget) {
		return;
	}
	
	if (currentTarget.contains(target) && !currentTarget.contains(relatedTarget) && relatedTarget.id !== 'menu-btn' && relatedTarget.id !== 'gatsby-focus-wrapper' && relatedTarget.id !== 'home') {
		setMenuOpen(false);
	}
}} onClick={(e) => {
	// const target_tag = e?.target?.tagName?.toLowerCase();

	// if (target_tag === 'a' || target_tag === 'img') {
		setMenuOpen(false);
	// }	
}} onKeyUp={(e) => {
	if (menu_open && e.key === 'Escape') {
		setMenuOpen(false);
	}
}}>
	<div className="nav-inner-wrap">
		<nav aria-label="Tal's Guide main navigation" role="navigation">
			<div className="nav-col">
				<div>
					<h3><Link to="/guitars">Talman Guitars</Link></h3>
					<ul>
						<li><Link to="/guitars/electric">Electric</Link>
							<ul>
								<li><Link to="/guitars/electric#solid-body">Solid body</Link></li>
								<li className="coming-soon-link"><Link to="/guitars/electric#semi-hollow">Semi-hollow</Link></li>
								<li className="coming-soon-link"><Link to="/guitars/electric#acoustic-electric">Acoustic / electric</Link></li>
							</ul>
						</li>
						<li className="coming-soon-link"><Link to="/guitars/acoustic">Acoustic</Link>
							<ul>
								<li><Link to="/guitars/acoustic#acoustic-electric">Acoustic / electric</Link></li>
								<li><Link to="/guitars/acoustic#classical-nylon">Classical / nylon</Link></li>
								<li><Link to="/guitars/acoustic#ukulele">Ukulele</Link></li>
							</ul>
						</li>
						<li className="coming-soon-link"><Link to="/guitars/related">Related guitars</Link>
							<ul>
								<li><Link to="/guitars/related#ibanez">Ibanez</Link></li>
								<li><Link to="/guitars/related#starfield">Starfield</Link></li>
								<li><Link to="/guitars/related#others">Others</Link></li>
							</ul>
						</li>
					</ul>
				</div>

				<div>
					<h3><Link to="/guitars/by-series">By Series</Link></h3>
					<ul>
						<li><Link to="/guitars/vintage">Vintage (1994-1998)</Link></li>
						<li className="coming-soon-link"><Link to="/guitars/artcore">Artcore (2002-2005)</Link></li>
						<li className="coming-soon-link"><Link to="/guitars/reissue">Reissue (2015+)</Link></li>
						<li className="coming-soon-link"><Link to="/guitars/signature">Signature models</Link></li>
					</ul>
				</div>
			</div>

			<div className="nav-col">
				<div className="coming-soon-link">
					<h3><Link to="/basses">Talman Basses</Link></h3>
					<ul>
						<li><Link to="/basses/electric#solid-body">Solid body electric</Link></li>
						<li><Link to="/basses/electric#acoustic-electric">Acoustic / electric</Link></li>
					</ul>
				</div>
				<div className="coming-soon-link">
					<h3><Link to="/artists">Artists</Link></h3>
					<ul>
						<li><Link to="/artists#signature">Signature artists</Link></li>
						<li><Link to="/artists#ibanez">Other Ibanez artists</Link></li>
						<li><Link to="/artists#notable-players">Notable players</Link></li>
					</ul>
				</div>

				<div>
					<h3><Link to="/media">Media</Link></h3>
					<ul>
						<li><Link to="/ads">Ads</Link></li>
						<li><Link to="/catalogs">Catalogs</Link></li>
						<li className="coming-soon-link"><Link to="/magazines">Magazines</Link></li>
						{/* <li><Link to="/">Price lists</Link></li> */}
						<li><Link to="/video">Video</Link></li>
						{/* <li><Link to="/misc">Misc</Link></li> */}
					</ul>
				</div>
			</div>

			<div className="nav-col">
				
				<div>
					<h3><Link to="/store">Store</Link></h3>
					<ul>
						<li><Link to="/store/guitars">Guitars</Link></li>
						<li><Link to="/store/basses">Basses</Link></li>
						<li><Link to="/store/parts">Parts</Link></li>
						<li><Link to="/store/products/tals-guide-gift-card">Gift Cards</Link></li>
						{/* <li><Link to="/store/other">Other</Link></li> */}
					</ul>
				</div>
				<div>
					<h3><Link to="/explore">Explore</Link></h3>
					<ul>
						<li><Link to="/news">News</Link></li>
						{/* <li><Link to="/">Talman history</Link></li> */}
						<li><Link to="/glossary">Glossary</Link></li>
						{/* <li><Link to="/">Price guide</Link></li> */}
						{/* <li><Link to="/">Video gallery</Link></li> */}
						{/* <li><Link to="/">Identify my talman</Link></li> */}
						{/* <li><Link to="/">Decode serial</Link></li> */}
					</ul>
				</div>
			</div>

			<div className="nav-col">
				<div>
					<h3><Link to="/about">Tal&#8217;s Guide</Link></h3>
					<ul>
						<li><Link to="/about">About</Link></li>
						<li><Link to="/help-find">Help me find a Talman</Link></li>
						<li><Link to="/contact">Sell your Talman</Link></li>
						<li><Link to="/contact">Contact</Link></li>
						{/* <li><Link to="/">Donate</Link></li> */}
						{/* <li><Link to="/">Link in bio</Link></li> */}
					</ul>
				</div>

				<NavFeature />
			</div>
		</nav>
	</div>
 </div>;
}


export default MainNav
