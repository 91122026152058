import React, { useEffect, useState } from "react"

import {Link} from "gatsby"
import MainNav from "./MainNav"
import PropTypes from "prop-types"
import { StaticImage } from "gatsby-plugin-image"
import { StoreContext } from "../context/store-context"

const Header = ({siteTitle, headerOverlap}) => {
  const [menu_open, setMenuOpen] = useState(false);
  const { checkout } = React.useContext(StoreContext);

  let cart_count;


  if (checkout?.lineItems.length) {
    cart_count = checkout.lineItems.reduce(function (accumulator, x) {
        return accumulator + x.quantity;
    }, 0);
  }

  useEffect(() => {
    const onBlur = ({relatedTarget}) => {
      if (menu_open && !relatedTarget) { // NOTE: tabbed up and out, or window lost focus
        setMenuOpen(false);
      }
    };

    document.getElementById('___gatsby').addEventListener('focusout', onBlur);

    return () => {
      document.getElementById('___gatsby').removeEventListener('focusout', onBlur);
    };
  }, [menu_open]);

  return (<>
    <div className="skip-link">
    Skip to <a href='#main' onClick={() => { setMenuOpen(false); }}>content</a> or <a href='#footer' onClick={() => { setMenuOpen(false); }}>footer</a>
    </div>
    
    <header className={`w-full ${headerOverlap ? 'absolute' : ''}`}>
      
      <div className={`flex justify-between content-center z-50 px-5 py-3 sm:px-12 sm:py-6 md:py-8 w-full max-w-1920 mx-auto ${menu_open ? 'fixed left-1/2 -translate-x-1/2 transform bg-black bg-opacity-70 backdrop-filter backdrop-blur-sm lg:bg-transparent lg:backdrop-filter-none' : 'relative'} `}>
        <h1 className="logo max-w-110px md:max-w-48 lg:max-w-none">
          <Link to="/" id="home">
            <StaticImage layout="constrained" placeholder="none" loading="eager" width={250} className="img-wrap" src="../assets/images/talsguide-logo.png" alt={siteTitle + ' logo'} quality={100}  />
          </Link>
        </h1>
        
        <div className="flex space-x-8 items-center">
          <Link to="/store/cart" className="shopping-cart">
            <svg className="w-5" focusable="false" data-prefix="fas" data-icon="shopping-cart" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" aria-labelledby="shopping_cart_icon"><title id="shopping_cart_icon">Shopping cart</title><path fill="currentColor" d="M528.12 301.319l47.273-208C578.806 78.301 567.391 64 551.99 64H159.208l-9.166-44.81C147.758 8.021 137.93 0 126.529 0H24C10.745 0 0 10.745 0 24v16c0 13.255 10.745 24 24 24h69.883l70.248 343.435C147.325 417.1 136 435.222 136 456c0 30.928 25.072 56 56 56s56-25.072 56-56c0-15.674-6.447-29.835-16.824-40h209.647C430.447 426.165 424 440.326 424 456c0 30.928 25.072 56 56 56s56-25.072 56-56c0-22.172-12.888-41.332-31.579-50.405l5.517-24.276c3.413-15.018-8.002-29.319-23.403-29.319H218.117l-6.545-32h293.145c11.206 0 20.92-7.754 23.403-18.681z"></path></svg>
            {cart_count ? <span className="count text-shadow">{cart_count}</span>: null}
          </Link>
          
          <button id="menu-btn" className="text-lg font-medium focus:outline-none border-b-2 border-transparent focus:border-white" onClick={(e) => {
            e.preventDefault();
            setMenuOpen(!menu_open);
          }}>{menu_open ? 'Close' : 'Menu' }</button>
        </div>
      </div>

      <MainNav menu_open={menu_open} setMenuOpen={setMenuOpen} />
    </header>
  </>);
}


Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
